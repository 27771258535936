import Graphics = Phaser.GameObjects.Graphics
import Image = Phaser.GameObjects.Image
import { TimeDisplayElementOption } from './models/time-display-element-option.model'
import { ColorPhaser } from '../../../modeles/color'
import Rectangle = Phaser.Geom.Rectangle
import { Platform } from '../../../../app/core/models/platform-info-traffic'

export class PlatformDefaultDisplayElement extends Graphics {
  constructor(scene: Phaser.Scene, timeDisplayElementOption: TimeDisplayElementOption) {
    super(scene, { fillStyle: { color: ColorPhaser.TIME_DISPLAY.valueOf() } })
    this.visible = timeDisplayElementOption.visible
    const {x, y, width, height} = new TimeDisplayElementGeom()
    const DEFAULT_ROUND = 4
    this.fillRoundedRect(x, y, width, height, DEFAULT_ROUND)
  }
}

export class PlatformClosedDisplayElement extends Image {
  constructor(scene: Phaser.Scene) {
    const X_OFFSET = 22
    const Y_OFFSET = 10
    super(scene, X_OFFSET, Y_OFFSET, '')
    this.setTexture('PLATFORM_CLOSED')
  }
}

export class PlatformTargetDisplayElement extends Image {
  constructor(scene: Phaser.Scene) {
    const X_OFFSET = 22
    const Y_OFFSET = 10
    super(scene, X_OFFSET, Y_OFFSET, '')
    this.setTexture('TARGET_BACKGROUND')
  }
}

export class PlatformElapsedDisplayElement extends Image {
  constructor(scene: Phaser.Scene, platform: Platform) {
    const X_OFFSET = 22
    const Y_OFFSET = 10
    super(scene, X_OFFSET, Y_OFFSET, '')
    switch (platform.waitingTimeThreshold) {
      case 'NORMAL':
        this.setTexture('TARGET_DEFAULT')
        break
      case 'WARN':
        this.setTexture('TARGET_WARNING')
        break
      case 'DANGER':
        this.setTexture('TARGET_DANGER')
        break
      default:
        this.setTexture('TARGET_DEFAULT')
        break
    }
  }
}

export class TimeDisplayElementGeom extends Rectangle {
  constructor() {
    super(0, 0, 44, 20)
  }
}

export class PlatformTrack2ReferentialStationDisplayElement extends Image {
  constructor(scene: Phaser.Scene, timeDisplayElementOption: TimeDisplayElementOption) {
    const X_OFFSET = -10
    const Y_OFFSET = 10
    super(scene, X_OFFSET, Y_OFFSET, '')
    this.visible = timeDisplayElementOption.visible
    this.setTexture('REFERENTIAL_STATION')
  }
}

export class PlatformTrack1ReferentialStationDisplayElement extends Image {
  constructor(scene: Phaser.Scene, timeDisplayElementOption: TimeDisplayElementOption) {
    const X_OFFSET = 54
    const Y_OFFSET = 10
    super(scene, X_OFFSET, Y_OFFSET, '')
    this.visible = timeDisplayElementOption.visible
    this.setTexture('REFERENTIAL_STATION')
  }
}
