import { inject, Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { filter, mergeMap, shareReplay, takeUntil } from 'rxjs/operators'
import { ApiLinesSliderService } from '../../services/api-line-slider.service'
import { AgentDto, IncidentDto } from '../../models/live-event'

export type GameState = 'READY' | 'UNREADY'
export type TrackState = Record<string, {
  agentsLinkedForPlatformId: AgentDto[];
  incidentsLinkedForPlatformId: IncidentDto[]
}>

@Injectable()
export class GameStateService {
  private linesSliderService = inject(ApiLinesSliderService)

  public initializationGameState: BehaviorSubject<GameState> = new BehaviorSubject<GameState>('UNREADY')
  public initializationTrainState: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])
  public initializationTrackState: BehaviorSubject<GameState> = new BehaviorSubject<GameState>('UNREADY')
  public changingTrackState: BehaviorSubject<TrackState> = new BehaviorSubject<TrackState>({})

  getReferentialLineAfterGameInitialisation$() {
    return this.initializationGameState.pipe(
      shareReplay(1),
      filter((gameState) => gameState === 'READY'),
      mergeMap((_) => this.linesSliderService.currentLineState$),
    )
  }
}
