import { Component, Input } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { NgIf } from '@angular/common'

@Component({
  selector: 'app-icon-information',
  standalone: true,
  imports: [IonicModule, NgIf],
  templateUrl: './icon-information.component.html',
  styleUrl: './icon-information.component.scss',
})
export class IconInformationComponent {
  @Input() displayEventImage: string
  @Input() displayLineNameValue: string
}
