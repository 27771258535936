import { Component, Input, OnChanges } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { NgIf } from '@angular/common'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { ContainerTrackElement } from '../../../../phaser/game-objects/groups/track/track.element'
import { firstValueFrom } from 'rxjs'
import { LiveEventType } from '../../../../phaser/game-objects/groups/liveEvent/liveEvent.element'

@Component({
  selector: 'app-general-information',
  standalone: true,
  imports: [IonicModule, NgIf, TranslateModule],
  templateUrl: './general-information.component.html',
  styleUrl: './general-information.component.scss',
})
export class GeneralInformationComponent implements OnChanges {
  @Input() stationInformation: ContainerTrackElement

  private readonly TEAM_LABEL = 'app.shared.event.detail.team'
  private readonly INCIDENT_LABEL = 'app.shared.event.detail.incident'
  private readonly AND_LABEL = 'app.shared.event.detail.and'
  private readonly COMMA_LABEL = 'app.shared.event.detail.comma'
  private readonly labelsByEventType: Record<string, string> = {
    [LiveEventType.AGENT]: this.TEAM_LABEL,
    [LiveEventType.INCIDENT]: this.INCIDENT_LABEL,
  }
  eventInformationLabel: string

  constructor(private translate: TranslateService) {}

  async ngOnChanges() {
    this.eventInformationLabel = await this.buildEventInformationLabel()
  }

  private async buildEventInformationLabel() {
    const translations = await firstValueFrom(this.translate.get([this.TEAM_LABEL, this.AND_LABEL, this.INCIDENT_LABEL, this.COMMA_LABEL]))
    const eventsByTypeLength = this.stationInformation?.liveEventElement?.getNumberOfEventsByType().length

    const labels = this.stationInformation?.liveEventElement
      ?.getNumberOfEventsByType()
      .filter(([_, eventNumber]) => eventNumber !== 0)
      .reduce((acc: string, [eventType, eventNumber], index) => {
        const prefix: string = index === eventsByTypeLength - 1 && acc.length > 0 ? translations[this.AND_LABEL] : ''
        const suffix: string = index < eventsByTypeLength - 1 && acc.length > 0 ? translations[this.COMMA_LABEL] : ''

        return acc.concat(`${prefix} ${eventNumber} ${this.formatPluralization(translations[this.labelsByEventType[eventType]], eventNumber)}${suffix}`)
      }, '')
      .trim()

    return labels.length > 0 ? `(${labels})` : ''
  }

  private formatPluralization(text: string, count: number) {
    return count <= 1 ? text : `${text}s`
  }
}
