import { Component, Input } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { NgIf } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { IconInformationComponent } from '../event-detail/icon-information/icon-information.component'
import { RedirectionToEventComponent } from '../event-detail/redirection-to-event/redirection-to-event.component'
import { Incident } from '../../../../core/models/live-event'

@Component({
  selector: 'app-event-summary',
  standalone: true,
  imports: [IonicModule, NgIf, TranslateModule, IconInformationComponent, RedirectionToEventComponent],
  templateUrl: './event-summary.component.html',
  styleUrl: './event-summary.component.scss',
})
export class EventSummaryComponent {
  @Input() displayLineNameValue: string
  @Input() displayEventImage: string
  @Input() incident: Incident
  @Input() activeIncidentEvent: boolean
}
