import { Component, Input } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { NgForOf, NgIf } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { PlatformService } from '../../../core/services/platform.service'
import { Connection } from '../../../../phaser/modeles/lines'

@Component({
  selector: 'app-connection',
  standalone: true,
  imports: [IonicModule, NgIf, TranslateModule, NgForOf],
  templateUrl: './connection.component.html',
  styleUrl: './connection.component.scss',
})
export class ConnectionComponent {
  @Input() connections: Connection[]

  constructor(public readonly platformService: PlatformService) {}
}
