import { Component, Input } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { Incident } from '../../../../../core/models/live-event'
import { environment } from '../../../../../../environments/environment'

@Component({
  selector: 'app-redirection-to-event',
  standalone: true,
  imports: [IonicModule],
  templateUrl: './redirection-to-event.component.html',
  styleUrl: './redirection-to-event.component.scss',
})
export class RedirectionToEventComponent {
  @Input() incident: Incident

  redirectToLiveEvent(): string {
    return `${environment.liveEventsBaseUrl}/incidents/${this.incident?.eventId}/filactu`
  }
}
