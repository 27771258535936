import { Component, Input } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { TranslateModule } from '@ngx-translate/core'
import { Agent } from '../../../../core/models/live-event'

@Component({
  selector: 'app-team',
  standalone: true,
  imports: [IonicModule, TranslateModule],
  templateUrl: './team.component.html',
  styleUrl: './team.component.scss',
})
export class TeamComponent {
  @Input() agent: Agent
}
