import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { paths } from '../../../environments/paths'
import { UserProfile } from '../models/user-profile'
import { BehaviorSubject, Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { UserPreferences } from '../models/user-preferences'

@Injectable({
  providedIn: 'root',
})
export class ApiUserProfileService {
  public userProfile$: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null)

  constructor(private httpClient: HttpClient) {
  }

  getUserProfile(): Observable<UserProfile> {
    return this.httpClient.get<UserProfile>(`${paths.userProfile}`).pipe(tap(userProfile => this.userProfile$.next(userProfile)))
  }

  saveUserProfile(userProfile: UserProfile): Observable<UserProfile> {
    return this.httpClient.post<UserProfile>(paths.userProfile, userProfile).pipe(tap(userProfile => this.userProfile$.next(userProfile)))
  }

  saveUserPreferences(userPreferences: UserPreferences): Observable<UserProfile> {
    return this.saveUserProfile({ ...this.userProfile$.value, userPreferences })
  }

}
