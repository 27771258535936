import { Component, Input } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { TranslateModule } from '@ngx-translate/core'
import { Incident } from '../../../../core/models/live-event'
import { IconInformationComponent } from '../event-detail/icon-information/icon-information.component'

@Component({
  selector: 'app-incident',
  standalone: true,
  imports: [IonicModule, TranslateModule, IconInformationComponent],
  templateUrl: './incident.component.html',
  styleUrl: './incident.component.scss',
})
export class IncidentComponent {
  @Input() incident: Incident
  @Input() displayLineNameValue: string
  @Input() displayEventImage: string
}
