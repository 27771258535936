<ion-row class="ion-align-items-center">
  <span *ngIf="stationInformation.platformState === 'CLOSED'"
        class="closed">
    {{ 'app.shared.station.information.closed' | translate }}
  </span>
  <span
    class="station-title">{{ stationInformation?.platformName }}{{ 'app.shared.station.information.track' | translate }}{{ stationInformation?.track }}</span>
  <ion-text> {{ eventInformationLabel }}</ion-text>
  <div class="wrapper-management">
    <ion-icon *ngIf="!!stationInformation?.management" class="small-size-icon"
              src="./assets/icon/lines/{{ stationInformation.management }}.svg" />
    <ion-icon *ngIf="!!stationInformation?.management" class="small-size-icon king"
              src="./assets/icon/station-information/king.svg" />
  </div>
  <ion-icon *ngIf="!!stationInformation?.ciClub" class="xsmall-size-icon"
            src="./assets/icon/station-information/ci-club.svg"></ion-icon>
  <ion-icon *ngIf="!!stationInformation?.referentialStation" class="medium-size-icon"
            src="./assets/svgs/platform/stopwatch.svg"></ion-icon>
</ion-row>
