import { inject, Injectable } from '@angular/core'
import {
  SplitPosition,
  Track,
  TrackSplitConfig,
  TrafficLine,
  TrafficWindow,
  TrafficWindowId,
} from '../../../../phaser/modeles/lines'
import { MainScene } from '../../../../phaser/scenes/main.scene'
import { SceneType } from '../../../../phaser/managers/scene.manager'
import { BehaviorSubject } from 'rxjs'
import {
  ContainerTrackElement,
  GroupTrackElement,
  TrackElement,
} from '../../../../phaser/game-objects/groups/track/track.element'
import { MainGame } from '../../../../phaser/main.game'
import { Platform, PlatformInfoTraffic, TrafficStateType } from '../../models/platform-info-traffic'
import { ColorPhaser } from '../../../../phaser/modeles/color'
import { userPreferences } from '../../configurations/user/user.preferences'
import { AgentDto, IncidentDto, LiveEvent } from '../../models/live-event'
import { LiveEventElement } from '../../../../phaser/game-objects/groups/liveEvent/liveEvent.element'
import { HEIGHT_UNIT, WIDTH_UNIT } from '../../../../phaser/game-objects/groups/track/track.service'
import { GameStateService } from './game-state.service'

class TrackPosition {
  primaryBranch: number
  primaryBranchDoublePlatform: number
  primaryBranchTriplePlatform: number
  secondaryBranch: number
  secondaryBranchDoublePlatform: number
  switchCase: boolean
  startingSwitchCase: boolean
  platformSwitchCase: boolean
  platformSwitchTimeCase: boolean | undefined
  triplePlatformSwitchCase: boolean

  constructor(offsetX: number) {
    this.primaryBranch = offsetX
    this.primaryBranchDoublePlatform = offsetX
    this.primaryBranchTriplePlatform = offsetX
    this.secondaryBranch = offsetX
    this.secondaryBranchDoublePlatform = offsetX
    this.switchCase = false
    this.startingSwitchCase = false
    this.platformSwitchCase = false
    this.platformSwitchTimeCase = false
    this.triplePlatformSwitchCase = false
  }
}

interface Position {
  offsetY: number
  track1: TrackPosition
  track2: TrackPosition
}

@Injectable()
export class LinesMappingService {
  private previouslyInterruptedPlatformWindowIds: string[] = new Array<string>()
  private previouslyColored: ContainerTrackElement[] = new Array<ContainerTrackElement>()
  private gameStateService = inject(GameStateService)

  static initPosition(offsetX = 0, offsetY = 0): Position {
    return {
      offsetY,
      track1: new TrackPosition(offsetX),
      track2: new TrackPosition(offsetX),
    }
  }

  static drawLineMapping(trafficLine: TrafficLine, scene: Phaser.Scene, isFullScreen = false) {
    const mainScene: MainScene = scene as MainScene
    const groupTrackElement: GroupTrackElement = mainScene.groupTrackElementLineMapping

    const containerTrackElements = trafficLine.positionMatrix.flatMap((matrixLine: TrafficWindowId[], indexY: number) => {
        const positionY = indexY * HEIGHT_UNIT + trafficLine.globalOffset.offsetY
        return matrixLine.map((trafficWindowId: TrafficWindowId, indexX: number) => {
          if (trafficWindowId &&
            (!matrixLine[indexX - 1] ||
              !(matrixLine[indexX - 1].windowId === trafficWindowId.windowId &&
                matrixLine[indexX - 1].trackNumber === trafficWindowId.trackNumber))) {
            const positionX = indexX * WIDTH_UNIT + trafficLine.globalOffset.offsetX
            const trafficWindow: TrafficWindow =
              trafficLine.tracks
                .find(track => track.track === +trafficWindowId.trackNumber)
                ?.windows
                ?.find(window => window.id === trafficWindowId.windowId)
            return new ContainerTrackElement(
              scene,
              trafficWindow,
              trafficLine.id,
              userPreferences,
              true,
              undefined,
              undefined,
              positionX,
              positionY)
          }
        }).filter(containerTrackElement => containerTrackElement)
      },
    )

    containerTrackElements.forEach((value) => {
      groupTrackElement.add(value, true)
    })

    // this.debugCdv(groupTrackElement, scene)
    return groupTrackElement
  }

  static drawLineMappingOld(tracks: Track, scene: Phaser.Scene, trackSplitConfig: TrackSplitConfig, isFullScreen = false): GroupTrackElement {
    const mainScene: MainScene = scene as MainScene
    const groupTrackElement: GroupTrackElement = mainScene.groupTrackElementLineMapping
    const position = new BehaviorSubject<Position>(null)
    const stationsNamesByNumber: Map<string, number> = this.getStationNamesByNumber(tracks)

    const containerTrackElements = tracks.trafficWindow.map((elementsTracks) => {
      position.next(LinesMappingService.initPosition())

      return elementsTracks.map((traficWindow: TrafficWindow, _: number) => {
        const splitWindows: SplitPosition =
          trackSplitConfig ?
            trackSplitConfig.splitPositions.find((window) => window.windowNumber1 === Number(traficWindow.id) || window.windowNumber2 === Number(traficWindow.id)) :
            null

        if (splitWindows && isFullScreen) {
          const newPosition = this.initPosition(splitWindows.offsetX, splitWindows.offsetY)
          position.next(newPosition)
        }

        return new ContainerTrackElement(
          scene,
          traficWindow,
          tracks.line,
          userPreferences,
          false,
          this.mustShowStationName(traficWindow.platFormName, traficWindow.track, stationsNamesByNumber),
          position)
      })
    })

    containerTrackElements
      .reduce((acc, r) => acc.concat(r))
      .forEach((value) => {
        groupTrackElement.add(value, true)
      })

    // this.debugCdv(groupTrackElement, scene)
    return groupTrackElement
  }

  // Can be removed safely but is also very useful for development
  private static debugCdv(groupTrackElement: GroupTrackElement, scene: Phaser.Scene) {
    groupTrackElement.children.entries.forEach((value, _) => {
      const container: ContainerTrackElement = value as ContainerTrackElement
      let offset = 6
      if (container.track === 2) {
        offset = -15
      }
      container.add(
        new Phaser.GameObjects.Text(scene, 0, container.positionYOfCdv + offset, String(container.id), {
          color: ColorPhaser.STATION_NAME_HEX as string,
          fontStyle: 'bold',
          fontSize: '8px',
          fontFamily: 'Arial',
        }),
      )
    })
  }

  private static getStationNamesByNumber(tracks: Track): Map<string, number> {
    const numberOfStationNames: Map<string, number> = new Map<string, number>()
    tracks.trafficWindow.forEach((trafficWindows) => {
      trafficWindows.forEach((trafficWindow) => {
        const platFormName = trafficWindow.platFormName
        const number = numberOfStationNames.get(platFormName)
        if (number) {
          numberOfStationNames.set(platFormName, number + 1)
        } else {
          numberOfStationNames.set(platFormName, 1)
        }
      })
    })
    return numberOfStationNames
  }

  private static mustShowStationName(line: string, track: number, stationsNamesByNumber: Map<string, number>) {
    if (line === 'XXXX') {
      return false
    }
    if (track === 1) {
      return true
    } else {
      return stationsNamesByNumber.get(line) === 1
    }
  }

  clearLine(phaserGame: Phaser.Game): void {
    if (!!(phaserGame.scene.getScene(SceneType.MAIN) as MainScene) && !!(phaserGame.scene.getScene(SceneType.MAIN) as MainScene).groupTrackElementLineMapping) {
      ;(phaserGame.scene.getScene(SceneType.MAIN) as MainScene).groupTrackElementLineMapping.clear(true, true)
      this.previouslyColored = new Array<ContainerTrackElement>()
      this.previouslyInterruptedPlatformWindowIds = new Array<string>()
    }
  }

  updateInterruptedLines(phaserGame: MainGame, platformInfoTraffic: PlatformInfoTraffic) {
    const groupTrackElement: GroupTrackElement = (phaserGame.scene.getScene(SceneType.MAIN) as MainScene).groupTrackElementLineMapping

    const interruptedPlatforms: Platform[] = platformInfoTraffic.platforms.filter((platform) => platform.trafficState === TrafficStateType.INTERRUPTED)

    if (this.haveInterruptedPlatformsChanged(interruptedPlatforms, this.previouslyInterruptedPlatformWindowIds)) {
      this.cleanPreviousInterruptions()
      const platformToColor: ContainerTrackElement[] = new Array<ContainerTrackElement>()
      const cdvToColor: ContainerTrackElement[] = new Array<ContainerTrackElement>()

      interruptedPlatforms.forEach((interruptedPlatform) => {
        const containerByPlatformTimeId = groupTrackElement.getContainerByPlatformTimeId(interruptedPlatform.windowNumber)
        if (containerByPlatformTimeId) {
          platformToColor.push(containerByPlatformTimeId)
          cdvToColor.push(...this.getLinkedCdvsToColor(containerByPlatformTimeId, groupTrackElement))
        }

        if (containerByPlatformTimeId?.platformDouble) {
          const platformDouble = groupTrackElement.getAllContainerByTrafficWindow(containerByPlatformTimeId.platformDouble)
          platformDouble.forEach((p) => {
            platformToColor.push(p as ContainerTrackElement)
          })
          cdvToColor.push(...this.getLinkedCdvsToColor(containerByPlatformTimeId, groupTrackElement))
        }
      })

      this.previouslyInterruptedPlatformWindowIds = interruptedPlatforms.map((value) => value.windowNumber)

      platformToColor.forEach((containerToColor) => {
        this.setInterruptedColor(containerToColor)
        this.previouslyColored.push(containerToColor)
      })

      const cdvIds = cdvToColor.map((value) => value.id)
      cdvToColor.forEach((value) => {
        if (cdvIds.filter((id) => id === value.id).length > 1) {
          this.setInterruptedColor(value)
          this.previouslyColored.push(value)
        }
      })
    }
  }

  updateLiveEvents({ phaserGame, incidents, agents }: {
    phaserGame: MainGame;
    incidents: IncidentDto[];
    agents: AgentDto[]
  }) {
    ;(phaserGame.scene.getScene(SceneType.MAIN) as MainScene).groupTrackElementLineMapping.getLiveEvents().forEach((liveEventElement: LiveEventElement) => {
      liveEventElement.updateStateFrom({
        liveEventElement,
        incidents,
        agents,
        trackStateSubject: this.gameStateService.changingTrackState
      })
    })
  }

  private getLinkedCdvsToColor(containerByPlatformTimeId: ContainerTrackElement, groupTrackElement: GroupTrackElement): ContainerTrackElement[] {
    const childElementsToColor: ContainerTrackElement[] = new Array<ContainerTrackElement>()
    if (containerByPlatformTimeId.platformLinkedCdv) {
      containerByPlatformTimeId.platformLinkedCdv.forEach((linkedCdv) => {
        const linkedCdvContainers = groupTrackElement.getAllContainerByTrafficWindow(linkedCdv)
        if (linkedCdvContainers.length > 0) {
          linkedCdvContainers.forEach((linkedCdvContainer) => {
            childElementsToColor.push(linkedCdvContainer as ContainerTrackElement)
          })
        }
      })
    }
    return childElementsToColor
  }

  private cleanPreviousInterruptions() {
    this.previouslyColored.forEach((previouslyColored) => {
      if (previouslyColored && previouslyColored.trackElement) {
        previouslyColored.trackElement.setColorOfElementIfNotTrackSwitch()
      }
    })
    this.previouslyColored = new Array<ContainerTrackElement>()
  }

  private setInterruptedColor(containerTrackElement: ContainerTrackElement) {
    const trackElement: TrackElement = containerTrackElement.trackElement
    trackElement.tint = ColorPhaser.INTERRUPTED_TRAFFIC
  }

  private haveInterruptedPlatformsChanged(interruptedPlatforms: Platform[], previouslyInterruptedPlatformWindowTimeIds: string[]) {
    const current = interruptedPlatforms.map((value) => value.windowNumber)
    return JSON.stringify(current.sort()) !== JSON.stringify(previouslyInterruptedPlatformWindowTimeIds.sort())
  }
}
