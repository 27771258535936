<div class="incident-wrapper">
  <div class="incident-information">
    <app-icon-information
      [displayEventImage]="displayEventImage"
      [displayLineNameValue]="displayLineNameValue"
    />
    <ion-text>{{ incident?.typeLabel }}</ion-text>
    <ion-text>{{ 'app.shared.event.detail.rangeDate' | translate }} : <span>{{ incident?.startTime }}</span></ion-text>
  </div>
</div>
