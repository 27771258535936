<div>
  <app-icon-information
    *ngIf="!activeIncidentEvent"
    [displayLineNameValue]="displayLineNameValue"
    [displayEventImage]="displayEventImage"
  />
  <app-redirection-to-event
    *ngIf="activeIncidentEvent"
    [incident]="incident"
  />
</div>
