export function sortAlphaNum(): (a: string, b: string) => number {
  return (first: string, second: string) => {
    const aA = first.replace(/[a-zA-Z]+/g, '')
    const bA = second.replace(/[a-zA-Z]+/g, '')
    const firstNumber = Number(aA)
    const secondNumber = Number(bA)
    if (firstNumber === 0) {
      return 1
    }
    return firstNumber < secondNumber ? -1 : 1
  }
}

export function areListsEqual(list1, list2) {
  if (list1.length !== list2.length) return false
  if (list1.length === 0 && list2.length === 0) return true

  const sortedList1 = [...list1].sort()
  const sortedList2 = [...list2].sort()

  return sortedList1.every((value, index) => value === sortedList2[index])
}