<div *ngIf="connections?.length > 0; else noConnexion"
     [class.correspondence]="platformService.isPlatformDesktop()" class="connection-wrapper">
  <div class="content-title ion-text-left">
    {{ 'app.shared.station.information.lines' | translate }}
  </div>
  <ion-icon *ngFor="let connection of connections" class="medium-size-icon"
            src="./assets/icon/lines/{{ connection.name }}.svg" />
</div>

<ng-template #noConnexion>
  <ion-col class="no-connexion-col">
    <ion-row>
      <ion-icon src="./assets/icon/station-information/no-connection.svg" />
    </ion-row>
    <ion-row>
      {{ 'app.shared.station.information.no.connection' | translate }}
    </ion-row>
  </ion-col>
</ng-template>
